import React from 'react'

import logo from '../images/logo.svg'

export const NavBar = () => {
  return (
    <nav className="bg-primary-surface shadow-md lg:shadow-none relative pt-6 px-4 sm:flex sm:pb-2 sm:px-6 lg:pb-6 lg:pl-8 lg:pr-0  items-center justify-between lg:justify-start">
      <div className="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
        <h1 className="flex items-center justify-between w-full md:w-auto">
          <a href="/" aria-label="Home">
            <img className="h-8 w-auto sm:h-10" src={logo} alt="WriteOps" />
          </a>
        </h1>
      </div>
      <ul className="flex mt-2 sm:mt-0 md:ml-auto relative z-10">
        <li>
          <a
            href="#services"
            className="font-medium text-primary-beta hover:text-primary-beta-highlight transition duration-150 ease-in-out hover:underline"
          >
            Services
          </a>
        </li>
        <li>
          <a
            href="#articles"
            className="ml-8 font-medium text-primary-beta hover:text-primary-beta-highlight transition duration-150 ease-in-out hover:underline"
          >
            Content
          </a>
        </li>
        <li>
          <a
            href="#contact"
            className="ml-8 font-medium text-primary-beta hover:text-primary-beta-highlight transition duration-150 ease-in-out hover:underline"
          >
            Contact Us
          </a>
        </li>
      </ul>
      <svg
        className="absolute z-0 inset-x-0 bottom-0 h-full text-primary-surface transform translate-y-1/2 w-full z-index-0 lg:hidden"
        fill="currentColor"
        viewBox="0 0 100 100"
        preserveAspectRatio="none"
      >
        <polygon points="0,50 100,50 0,100 0,100"></polygon>
      </svg>
    </nav>
  )
}
