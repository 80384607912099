import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img, { FluidObject } from 'gatsby-image'
import { NavBar } from './NavBar'

export const Hero = () => {
  const { file } = useStaticQuery<Query>(query)

  return (
    <div className="relative bg-primary-surface overflow-hidden">
      <div className="max-w-screen-xl mx-auto">
        <div className="relative z-10 pb-8 bg-transparent lg:bg-primary-surface lg:max-w-2xl lg:w-full">
          <svg
            className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-primary-surface transform translate-x-1/2"
            fill="currentColor"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
          >
            <polygon points="50,0 100,0 50,100 0,100"></polygon>
          </svg>

          <NavBar />

          <div className="relative mx-auto max-w-screen-xl px-4 sm:px-6 lg:px-8">
            <div className="py-8">
              <h2 className="font-display text-4xl tracking-tight leading-10 font-extrabold text-primary-beta sm:text-5xl sm:leading-none md:text-6xl">
                Technical Content that <br className="lg:hidden" />
                <span className="text-primary-alpha">Builds a Community</span>.
              </h2>
              <div className="mt-3 space-y-2 text-base text-primary-gamma sm:mt-5 sm:text-lg sm:max-w-xl md:mt-5 md:text-xl lg:mx-0">
                <p>
                  Writing great technical content is hard, it takes skill and
                  expertise. That's where we come in.
                </p>
                <p>
                  We know what your target audience want to read, because we are
                  your audience.
                </p>
                <p>
                  Software engineers, writing technical content for software
                  engineers.
                </p>
              </div>
              <div className="mt-5 sm:mt-8 sm:flex">
                <div>
                  <a
                    href="#services"
                    className="font-display w-full flex items-center justify-center px-8 py-3 border border-primary-beta text-base leading-6 font-medium rounded-md text-primary-beta bg-transparent hover:text-primary-surface hover:bg-primary-beta hover:border-primary-beta transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10"
                  >
                    What We Do
                  </a>
                </div>
                <div className="mt-3 sm:mt-0 sm:ml-3">
                  <a
                    href="#contact"
                    className="font-display w-full flex items-center justify-center px-8 py-3 text-base border border-transparent text-primary-surface leading-6 font-medium rounded-md text-white bg-primary-alpha hover:bg-primary-alpha-highlight transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10"
                  >
                    Get in Touch
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="absolute inset-0 lg:inset-auto lg:inset-y-0 lg:right-0 lg:w-1/2">
        <Img
          className="opacity-10 lg:opacity-30 h-full w-full object-cover filter-grayscale+blur lg:filter-grayscale"
          fluid={file.childImageSharp.fluid}
          alt="Man typing technical content on laptop"
        />
      </div>
    </div>
  )
}

interface Query {
  file: {
    childImageSharp: {
      fluid: FluidObject
    }
  }
}

const query = graphql`
  query Hero {
    file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "hero/bg.jpg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
