import React from 'react'

export const Footer = () => {
  return (
    <footer
      id="footer"
      className="bg-secondary-surface text-secondary-gamma p-8"
    >
      <div className="container mx-auto text-gray-200">
        <p className="font-bold text-secondary-beta">WriteOps Ltd</p>
        <ul className="text-sm">
          <li>Registered in the United Kingdom</li>
          <li>Company Number: 12674250</li>
          <li>VAT Registration Number: 359953539</li>
        </ul>
      </div>
    </footer>
  )
}
